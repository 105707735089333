$(function () {

    $.connection.hub.logging = true;

    var hub = $.connection.notificationHub;

    hub.client.send = function (note) {
        console.log(note);
        var header = $('.notification-header-wrapper');
        if (note.count == 0) {
            header.removeClass('has-unreads');
        } else {
            header.addClass('has-unreads');
        };
        header.find('.js-unread-count').text(note.count);

        Push.create(note.title, {
            body: note.body,
            link: note.link,
            icon: '/content/images/windows/windows-tile.png',
            timeout: 4000,
            tag: 'notification-' + note.id,
            onClick: function () {
                window.focus();
                window.location = note.link;
                this.close();
            }
        });
    };

    hub.client.changeCount = function (count) {
        console.log(count);
        var header = $('.notification-header-wrapper');
        if (count == 0) {
            header.removeClass('has-unreads');
        } else {
            header.addClass('has-unreads');
        };
        header.find('.js-unread-count').text(count);
    };

    $.connection.hub.start().done(function () {
        console.log('Connected with id = : ' + $.connection.hub.id);
    });
});